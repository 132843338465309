import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Process = makeShortcode("Process");
const Link = makeShortcode("Link");
const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-ethereums-history-and-state",
      "style": {
        "position": "relative"
      }
    }}>{`🌍 Ethereum's History and State`}<a parentName="h1" {...{
        "href": "#-ethereums-history-and-state",
        "aria-label": " ethereums history and state permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`In Module 0, we introduced Kernel and gave you an outline of what this course aims teach you. We discussed the overall framework for thinking we will develop throughout our remaining work together and conveniently labelled it "`}<strong parentName="p">{`quantum thought`}</strong>{`". This is the ability to:`}</p>
    <Process mdxType="Process"> 
      <p>{`identify patterns both within and out there in the world; `}</p>
      <p>{`to understand the interplay of complementary opposites - 0 and 1 - which is the foundation of all pattern; `}</p>
      <p>{`to see more clearly the infinite spectrum of probabilities between the opposites without any irritable, egotistic grasping after
certainty; and `}</p>
      <p>{`to develop the sort of humility required to navigate this complex modern world.`}</p>
    </Process>
    <p>{`We then grounded this abstract framework through the example of `}<a parentName="p" {...{
        "href": "/learn/module-0/trust"
      }}>{`trust`}</a>{`. Once we can define in executable language all of the ways in which it is possible to cheat, we can develop "trustless" protocols which, in turn, allow us to explore new kinds of interpersonal, trust-full spaces between the actual people on either end of any given transaction. It is `}<strong parentName="p">{`both-and, then beyond`}</strong>{`.`}</p>
    <p>{`This led us into a wider exploration of conversation, purpose and intention. We time travelled back to 1879 with Vincent Van Gogh - who painted `}<Link to="https://www.brainpickings.org/2014/11/13/van-gogh-starry-night-fluid-dynamics-animation/" mdxType="Link">{`turbulent flow`}</Link>{` more than a century before mathematicians could even begin to grasp it - and then even further, to a lived experience with the First People and Paul Myburgh which allowed us to explore the ancient foundations of listening and being.`}</p>
    <h2 {...{
      "id": "stating-history",
      "style": {
        "position": "relative"
      }
    }}>{`Stating History`}<a parentName="h2" {...{
        "href": "#stating-history",
        "aria-label": "stating history permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We only brushed the surface of Bitcoin last week and there is a great deal more we could say
about it. There can be no doubt that it represents an incredibly important moment in the
movement towards money as a protocol, the development of new means to create or describe value,
and new media by which we can relate that to and with one another in an agreeable fashion.
However, it is not the whole story: only its beginning. Genesis is a wonderful book, but the
real work of setting people free occurs elsewhere.`}</p>
    <p>{`So, this week, we'll begin trying to understand what happens when you combine a peer-to-peer
architecture for the internet of money - and its immense intrinsic utility - with a Turing-complete
programming language. That is, we'll begin studying Ethereum. `}</p>
    <p>{`We are not abandoning Bitcoin - many more references to it follow - but rather trying to come
to a holistic understanding of all the competing and complementary developments in the technology
of money since 2009. We do this in the pursuit of an ever-deepening understanding of the
underlying patterns which power this new family of valuable protocols.`}</p>
    <h2 {...{
      "id": "week-1-firesides",
      "style": {
        "position": "relative"
      }
    }}>{`Week 1 Firesides`}<a parentName="h2" {...{
        "href": "#week-1-firesides",
        "aria-label": "week 1 firesides permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Toby Shorin - September 22, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/t2bNGRfwNDw?start=335" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Niran Babalola - October 7, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/uN4g0Sr3jhs?start=406" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Kevin Owocki - May 27, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/5fLOj79f2kw?start=560" mdxType="Video" />
      </Box>
    </Column>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      